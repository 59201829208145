import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

import mhLogo from '../images/mh-logo.svg';
import FooterWrapper from '../components/index/FooterWrapper';
import WantToTakeCareSection from '../components/index/WantToTakeCareSection';

const fontFamily = 'Merriweather';

const coreInfoKeys = ['Industry', 'Employees', 'Headquarters'];

const companiesUnder2K = [
    {
        name: 'Gusto',
        color: '#ee314d',
        coreInfo: ['Software', '1,000', 'San Francisco, CA'],
        description:
            'Gusto provides an integrated platform to automate and simplify payroll, benefits and human resources.',
        headerText: 'What is your overall approach for addressing mental health in the workplace?',
        paragraphs: [
            {
                content: `“Gusto provides foundational benefits because our benefits are designed to  give employees peace of mind. By partnering with Modern Health, we can offer employees a benefit that promotes emotional well-being, provides added support, and aligns with our belief that work should enable a better life.”`,
                author: '- Zoë Zeligs, Gusto People Partner',
            },
            {
                content: `“The nature of work here is mentally challenging and the option to have an outlet and someone to talk to helps me to deal with the pressure and stress of the work.”`,
                author: `- Gusto Employee`,
            },
        ],
    },
    {
        name: 'GitLab',
        color: '#1c4b81',
        coreInfo: ['Software', '650', 'Remote'],
        description:
            'Gitlab is a single application to manage the entire software development cycle.',
        headerText: 'GitLab as a workplace:',
        paragraphs: [
            {
                content: `As a fully distributed workforce, GitLab encourages employees to work wherever they're happiest. Flexible hours allow employees to do their best work without missing out on life's important moments. GitLab hires based on its values, including collaboration, transparency, and diversity.`,
            },
            {
                content: `Notably, GitLab champions Neurodiversity, "a type of diversity that includes Autism, ADHD, and other styles of neurodivergent functioning. While they often bring unique skills and abilities, which can be harnessed for competitive advantage, neurodivergent individuals are often discriminated against, and sometimes have trouble making it through traditional hiring processes. These individuals should be able to contribute as GitLabbers."`,
                author: `- GitLab Handbook`,
            },
        ],
    },
    {
        name: 'Flexport',
        color: '#324354',
        coreInfo: ['Software', '1,250', 'San Francisco, CA'],
        description:
            'Flexport is a software and data analytics focused freight forwarder that partners with businesses to transport goods with increased visibility.',
        headerText: 'Flexport as a workplace:',
        paragraphs: [
            {
                content: `"Before starting Flexport, I promised myself that I would always be my authentic self at work. As I made that commitment to be one authentic person, at work and in my personal life, I realized that to succeed I would need to create a culture for everyone else to do the same. Since Flexport's inception, we've worked hard to create a welcoming culture, not just for candidates and employees, but also for others in our ecosystem to be their authentic selves. We want people to know that their quirks will be appreciated, their diverse backgrounds celebrated, and their ideas taken seriously." `,
                author: '- CEO of Flexport, Ryan Petersen',
            },
        ],
    },
    {
        name: 'Buzzfeed',
        color: '#ee3624',
        coreInfo: ['Media', '2,000', 'New York, NY'],
        description:
            'Buzzfeed is a digital media platform with both a global news organization and entertainment studio, reaching and engaging millions globally.',
        headerText: 'Buzzfeed as a workplace:',
        paragraphs: [
            {
                content: `"BuzzFeed believes in providing employees with a holistic approach to their well-being, using a variety of options that allow them to address their health not just from the outside in, but from the inside out as well. We've been able to see the direct benefit in the form of teams who had demonstrated low work-life balance letting us know how engaged and inspired they were by these programs (and simply that BuzzFeed cared enough to try)."`,
                author: '- John Davisi, VP People Operations & Mindfulness',
            },
        ],
    },
    {
        name: 'Nextdoor',
        color: '#00b340',
        coreInfo: ['Social Network', '300', 'San Francisco, CA'],
        description:
            'Nextdoor is a free social network that encourages conversation between neighbors so they can stay informed about their community.',
        headerText: 'Nextdoor as a workplace:',
        paragraphs: [
            {
                content: `"The most important thing that companies can do to address mental health in the workplace is to start talking about it like the serious issue that it is today. The stigma around this issue is real. When coworkers can talk about these problems openly with each other and with their leaders, it creates an environment where the right level of care can emerge for those who need it. Modern Health offers education, tools and support across a spectrum of mental health challenges. It's an excellent way to meet employees where they are and to provide them with the type of help they need, at their own pace and on their own terms." `,
                author: '- Bryan Power, Chief People Officer',
            },
        ],
    },
    {
        name: 'Zume',
        color: '#3c4e62',
        logoExt: 'png',
        coreInfo: ['Logistics', '450', 'Mountain View, CA'],
        description:
            'Zume is a food delivery company with an end-to-end platform that offers the ability to cook en route and forecast both supply and consumer demand.',
        headerText: 'What is your overall approach for addressing mental health in the workplace?',
        paragraphs: [
            {
                content: `"It is a priority for Zume to lay down a strong and employee-centered foundation for all of our People Programs as we continue to grow. We are taking  a proactive approach by including a wellness platform early in our life cycle as a company so that we can support our employees. Instead of focusing our efforts on a traditional Employee Assistance Program, Zume has decided to invest in a more comprehensive well-being approach, which has had a positive response from our employee base - measured by our team's engagement and feedback on the Modern Health platform.`,
            },
            {
                content: `By empowering employees to take on this proactive approach to mental health, they will be much better equipped to handle a disruption than only seeking out guidance when something is wrong. Mental health is very similar to physical health in the context that you need to be constantly working towards a goal and regular visits with a professional (or self-guided) will improve your day to day life."`,
                author: `- Zume People Team`,
            },
        ],
    },
    {
        name: 'Gravity Payments',
        color: '#2e3d4e',
        coreInfo: ['Software', '200', 'Seattle, WA'],
        logoExt: 'png',
        description:
            'Gravity Payments is a credit card processor for independent businesses that focuses on low cost solutions and readily available customer support.',
        headerText: 'What is your overall approach for addressing mental health in the workplace?',
        paragraphs: [
            {
                content:
                    '“I believe that more than anything else we don’t currently talk about mental health in the workplace. The core of my approach for addressing mental health is creating awareness and creating an open space for conversation. I participated in a panel session where I asked the audience to put their hand up if they’d ever experienced or been diagnosed with a mental illness. Half the hands in the room went up. When you see that, it’s hard to argue that mental illness is some mental or moral weakness.  If people at work start to understand how many of their colleagues are experiencing mental illness, it very quickly becomes normalized. I have the fortunate position of having a mental illness – bipolar type 2 -  and a job title that society views as important (I’m a VP). So when I talk about my mental illness it allows more people to come forward and let others know that they’re struggling too.”',
                author: '- James Pratt, VP of People Development',
            },
        ],
    },
    {
        name: 'Toast',
        color: '#ff4c00',
        coreInfo: ['Software', '1,000', 'Boston, MA'],
        description:
            'Toast is a point of sale and management system designed for restaurants to manage operations, sales and guest experience.',
        headerText: 'Toast as a workplace: ',
        paragraphs: [
            {
                content:
                    '“ComplexCarbs is an Employee Resource Group created to combat stigma and normalize conversation around mental health in the workplace. Our mission is to support the health and well-being of *all* Toasters. We increase awareness of mental health and disability issues, destigmatize struggles related to mental health and disability, and normalize conversations about our experiences. We advocate for our community through every channel available, including providing educational resources and influencing policy at Toast. Our vision is a Toast where every Toaster is empowered to live their best life knowing that they are supported, even on their worst days.”',
                author: '- ComplexCarbs Employee Resource Group @ Toast',
            },
        ],
    },
    {
        name: 'Slack',
        color: '#4a154b',
        coreInfo: ['Software', '1,000', 'San Francisco, CA'],
        description:
            'Slack is a collaboration platform that integrates across organizational apps and services to increase visibility and access to information within teams.',
        headerText: 'Slack as a workplace: ',
        paragraphs: [
            {
                content:
                    '“I have never seen a company so dedicated to its values. At Slack, it isn’t really any list of values, so much as it is an implied way of being. If you are collaborative, respectful, put team first, find creative solutions, then you will be more successful here. Working at Slack, I feel liberated to do my best work. Stuart is amazing. The hype is real.”',
                author: '- Slack Employee',
            },
        ],
    },
    {
        name: 'Boston Beer Company',
        color: '#0e264c',
        coreInfo: ['Food & Beverage', '1,900', 'Boston, MA'],
        description:
            'Boston Beer is a brewer founded in 1984.  Their brands include Samuel Adams, Angry Orchard Cider, Truly Hard Seltzer, Twisted Tea, and most recently, Dogfish Head, among others.',
        headerText: 'Boston Beer Company as a workplace: ',
        paragraphs: [
            {
                content:
                    '“We take pride in caring for the whole individual at Boston Beer.  We make sure that we pay as much attention to mental health as physical health, and our benefits reflect that.  Stress and anxiety plague our society and we have a responsibility as employers to help coworkers [Boston Beer employees] manage their mental health.”',
                author: '- Dave Burwick, CEO',
            },
        ],
    },
];

const companiesOver2K = [
    {
        name: 'EY',
        color: '#575756',
        coreInfo: ['Consulting', '250,000', 'London, UK'],
        description:
            'EY is a global consulting firm within the accounting sector that specializes in Assurance, Advisory, Tax and Transaction Advisory Services.',
        headerText: 'EY as a workplace:',
        paragraphs: [
            {
                content: `“A company that really cares about their people. Allows for flexible work
                environments as long as you communicate and complete your work in a timely
                manner. Many benefits including reimbursement for certain health and fitness
                expenses, long summer holiday weekends, and referral bonuses. Most of the people I
                work with are caring, dynamic, professional, and entertaining individuals.” `,
                author: '- EY Employee',
            },
        ],
    },
    {
        name: 'Linkedin',
        color: '#2e3d4e',
        coreInfo: ['Software', '20,000', 'Mountain View, CA'],
        description: `LinkedIn is the largest social media professional network, with tools for both
        individuals and organizations to improve hiring processes, network
        engagement and professional development.`,
        headerText: 'LinkedIn as a workplace:',
        paragraphs: [
            {
                content: `“At LinkedIn, we believe that our people are the key to our success. As such, we
                believe it's critical to ensure that people feel engaged, like they belong, and that
                they have the resources to be successful at work and in their personal lives. We know
                that mental well-being plays a huge role in this, and that is why we invest in
                understanding how our employees are feeling day to day, and giving employees the
                tools they need to be resilient to everyday work and life. Our focus on employee
                well-being is backed up by advocacy and action from everyone in the company all
                the way to the top.”`,
                author: '- John Jersin, VP Product LTS',
            },
        ],
    },
    {
        name: 'Workday',
        color: '#066bd0',
        coreInfo: ['Software', '11,000', 'Pleasanton, CA'],
        description:
            'Workday is an enterprise cloud application that brings together finance and HR, increasing data visibility between the two divisions.',
        headerText: 'Workday as a workplace: ',
        paragraphs: [
            {
                content:
                    '“We’re lucky to have a lot of options at Workday. I know that if something is going on I can turn to management and they’ll lend a welcoming ear. We have access to coaches to help guide us in both professional and personal endeavors. There’s no question that Workday cares a lot about its people. I feel lucky to be a part of the team.”',
                author: '- Workday Employee',
            },
        ],
    },
    {
        name: 'Salesforce',
        color: '#ffffff',
        withShadow: true,
        coreInfo: ['Software', '20,000', 'San Francisco, CA'],
        description:
            'Salesforce’s customer success cloud-based platform allows organizations to transparently monitor, service and engage with their client relationships.',
        headerText: 'Salesforce as a workplace: ',
        paragraphs: [
            {
                content:
                    '“Camp Pono is widely promoted at our company. It lets all employees know that their wellness is a priority. We have tons of options ranging from the mindfulness zone to a monthly stipend that we can spend on anything wellness related. Giving employees this freedom will do a lot to reduce the stigma and allow people the flexibility to approach their well-being at their own pace."',
                author: '- Salesforce Employee',
            },
        ],
    },
    {
        name: 'Ultimate Software',
        color: '#4d9d45',
        coreInfo: ['Software', '4,000', 'Weston, FL'],
        description:
            'Ultimate Software offers a cloud-based HR software for human capital management, payroll and employee experience solutions.',
        headerText: 'Ultimate Software as a workplace: ',
        paragraphs: [
            {
                content: `“Ultimate has great benefits, including full medical, dental, and vision for employees and family.  There's a great work-life balance, thanks to flexible scheduling.  Not to mention, it's a great environment and culture where employees are given lots of perks to make daily life easier and learning and growth opportunities to develop.  Management is accessible and willing to assist at all levels.”`,
                author: '- Ultimate Software Employee',
            },
        ],
    },
    {
        name: 'Intuit',
        color: '#365ebe',
        logoExt: 'png',
        coreInfo: ['Software', '11,000', 'Mountain View, CA'],
        description:
            'Intuit is a financial software company that provides accounting, finance and tax programs for businesses and individuals.',
        headerText: 'What is your overall approach for addressing mental health in the workplace?',
        paragraphs: [
            {
                content: `“Our overall approach is to simply address emotional health so our employees feel less stressed and are more productive in their everyday life. We wanted to start the emotional wellness journey here-- there have been lots of studies around how there’s been a lot of stress and anxiety with our society lately and we believe that we can help--not only through medication but also though skill building and providing tools to help people of all different needs.`,
            },
            {
                content: `We know that one size does not fit all and we have taken on a multi-faceted approach with mental health at Intuit--we’re also just beginning! To do so we provide everything from meditation tools to mental health first aid training to simply making it easier to get connected to care. Whether you’re just experiencing minor stress or need clinical care it doesn’t matter--we want to provide something to help for everyone.`,
            },
            {
                content: `The main message I want to leave other companies is do not be afraid to tackle it! You can do it, even if it feels scary. Take the time to understand what works with your culture but please-- don’t be afraid to talk about it. That only adds to the stigma.”`,
                author: '- Jojo Baca Carroll, Sr. Benefits Analyst',
            },
        ],
    },
    {
        name: 'Atlassian',
        color: '#2684ff',
        coreInfo: ['Software', '4,000', 'Sydney, AUS'],
        description:
            'Atlassian provides enterprise IT products for software development, product management and collaboration.',
        headerText: 'Atlassian as a workplace: ',
        paragraphs: [
            {
                content:
                    '“People CARE. They care about me, about my life, about my work, about the products, about my success, about my failures, about how to live the best possible work/life balance. There are no petty attitudes on my greater team of 30+, just people who understand when life happens and want you to do the best work possible.”',
                author: '- Atlassian Employee',
            },
        ],
    },
    {
        name: 'Hilton',
        color: '#00304d',
        coreInfo: ['Hospitality', '60,000', 'McLean, VA'],
        description:
            'Hilton is a global hospitality company with over 5,500 properties in 109 countries and territories.',
        headerText: 'Hilton as a workplace: ',
        paragraphs: [
            {
                content:
                    '“The culture of Hilton is one that makes me proud to work here but to also serve in my community. Hilton seeks to positively impact the communities in which we work. This speaks to our commitment to spread the light and warmth of hospitality. Beyond that, I am encouraged to have a balanced work-life with the flexibility of working from home. My management team appreciates and rewards hard work. Hilton believes in taking care of their team members which guarantees that we will take care of our guests. The benefits of "being Hilton" are getting better and better. The long tenure room discounts gives lifetime discounts for team members with 10 or more years.”',
                author: '- Hilton Employee',
            },
        ],
    },
    {
        name: 'SoulCycle',
        color: '#ffffff',
        withShadow: true,
        logoExt: 'png',
        coreInfo: ['Fitness', '3,000', 'New York City, NY'],
        description:
            'SoulCycle is a 45-minute indoor cycling class that features high-intensity cardio, muscle-sculpting strength training, and rhythm-based choreography.',
        headerText: 'SoulCycle as a workplace: ',
        paragraphs: [
            {
                content:
                    '“Mind, Body & Soul is something that is promoted across all the teams at SoulCycle. They live this and promote a culture that allows employees to feel supported for everything they’re working on. There’s a clear mission that SoulCycle is committed to promoting wellness globally. This ties in well with the culture at the company and I think all of our employees are behind it.”',
                author: '- SoulCycle Employee',
            },
        ],
    },
    {
        name: 'Patagonia',
        color: '#39338d',
        coreInfo: ['Retail', '2,200', 'Ventura, CA'],
        description:
            'Patagonia is an outdoor retailer known for pioneering the standard of their gear and social initiatives. They’ve long been recognized for their wellness programs and promoting a strong work-life balance. If your founder publishes a book called, ‘Let My People Go Surfing’ you know the employee’s well-being is top of mind. This is a large reason why employees are saying terrific things.',
        headerText: 'Patagonia as a workplace: ',
        paragraphs: [
            {
                content:
                    '“Patagonia offers so many ways for employees to live well. We have a program called brain food that helps us learn about different topics and promotes employees to have a conversation about mental health. This does a lot to reduce the stigma so that people feel safe when bringing up sensitive topics. Compared to other places I’ve worked, Patagonia is years ahead on this topic.”',
                author: '- Patagonia Employee',
            },
        ],
    },
];

const HeaderContainer = styled.div({
    maxWidth: 1200,
    margin: '0 auto',
    padding: '1.7rem 1.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
});

const BackLink = styled(Link)({
    fontWeight: 500,
    color: '#4d6580',
    fontSize: '0.8rem',
});

const BrandLogo = styled.img({
    width: '10rem',
});

const ContentWrapper = styled.div({
    maxWidth: 700,
    margin: '3.5rem auto',
    padding: '1rem',
});

const ArticleTitle = styled.h2({
    fontFamily: 'Avenir',
    fontWeight: 900,
    marginTop: '1.5rem',
    fontSize: '2.9rem',
    lineHeight: '3.5rem',
    color: '#2e3d4e',
});

const OrangeParagraph = styled.p({
    fontFamily,
    fontStyle: 'italic',
    fontWeight: 500,
    color: '#f98d4d',
    fontSize: '1.2rem',
    lineHeight: '1.9rem',
});

const RegularParagraph = styled.p({
    fontFamily,
    fontWeight: 500,
    color: '#2e3d4e',
    lineHeight: '1.8rem',
    marginBottom: '1.7rem',
    fontSize: '1.1rem',
});

const RegularItalicParagraph = styled(RegularParagraph)({
    fontStyle: 'italic',
    marginTop: '1.2rem',
});

const BoldParagraph = styled.p({
    fontFamily,
    fontWeight: 700,
    color: '#2e3d4e',
    lineHeight: '1.8rem',
    marginBottom: '2rem',
    fontSize: '1.1rem',
});

const BoldItalicParagraph = styled(BoldParagraph)({
    fontStyle: 'italic',
    marginBottom: '1.7rem',
});

const SectionTitle = styled.h3({
    fontFamily: 'Avenir',
    fontWeight: 900,
    marginTop: '3rem',
    fontSize: '2.1rem',
    color: '#2e3d4e',
});

const CompanyLogoWrapper = styled.div(({ color, withShadow }) => ({
    backgroundColor: color,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '9rem',
    borderRadius: '1rem',
    boxShadow: withShadow ? '0 6px 20px 0 rgba(204, 212, 213, 0.64)' : 'none',
}));

const CompanyName = styled.p({
    fontFamily: 'Avenir',
    fontWeight: 900,
    marginTop: '2rem',
    fontSize: '1.7rem',
    lineHeight: '2rem',
    color: '#2e3d4e',
    marginBottom: '0.5rem',
});

const CompanyInfoRow = styled.div({
    display: 'flex',
});

const CompanyInfoTextBold = styled.p({
    fontFamily,
    fontWeight: 700,
    color: '#2e3d4e',
    fontSize: '1.1rem',
    marginBottom: '0.5rem',
});

const CompanyDescription = styled.p({
    fontFamily,
    fontWeight: 500,
    color: '#2e3d4e',
    fontSize: '1.1rem',
    lineHeight: '2rem',
    marginTop: '0.7rem',
});

const CompanyInfoTextRegular = styled(CompanyDescription)({
    marginLeft: '0.3rem',
    marginBottom: '0.5rem',
    marginTop: '0',
    lineHeight: '1.5',
});

const Company = ({ company, index }) => (
    <div style={{ marginTop: '2rem' }}>
        <CompanyLogoWrapper color={company.color} withShadow={company.withShadow}>
            <img
                src={require(`../images/article/${company.name
                    .toLowerCase()
                    .split(' ')
                    .join('_')}.${company.logoExt ? company.logoExt : 'svg'}`)}
                alt="logo"
                style={{ maxHeight: 80 }}
            />
        </CompanyLogoWrapper>
        <CompanyName>{`${index + 1}. ${company.name}`}</CompanyName>

        {company.coreInfo &&
            company.coreInfo.map((info, i) => (
                <CompanyInfoRow key={info}>
                    <CompanyInfoTextBold>{coreInfoKeys[i]}</CompanyInfoTextBold>
                    <CompanyInfoTextRegular>{info}</CompanyInfoTextRegular>
                </CompanyInfoRow>
            ))}

        <CompanyDescription>{company.description}</CompanyDescription>

        <CompanyName>{company.headerText}</CompanyName>

        {company.paragraphs &&
            company.paragraphs.map(paragraph => (
                <div key={paragraph.content}>
                    <RegularItalicParagraph>{paragraph.content}</RegularItalicParagraph>
                    {paragraph.author && <BoldParagraph>{paragraph.author}</BoldParagraph>}
                </div>
            ))}
    </div>
);

const TopCompaniesPage = () => (
    <>
        <HeaderContainer>
            <BrandLogo src={mhLogo} alt="brand_logo" />

            <BackLink to="/">Back to homepage</BackLink>
        </HeaderContainer>
        <ContentWrapper>
            <ArticleTitle>Top Companies That Support Employees' Mental Well-Being</ArticleTitle>
            <OrangeParagraph>
                Suicide rates have gone up 30% in the last 18 years. It's the 10th leading cause of
                death. 1 in 4 of your friends, family, and coworkers struggles with a severe mental
                health issue like depression.
            </OrangeParagraph>
            <RegularParagraph>
                It's time we start taking care of ourselves and each other, and it can start at
                work. We spend 35% of our total waking hours at work (during 50 years of working).
                For those who work 40+ hours a week, we spend more time with the people at work than
                with our family. The point is that we, as employers, can play a huge role in
                creating a culture and an environment where it’s encouraged to invest in your mental
                well-being.
            </RegularParagraph>
            <RegularParagraph>
                With May being Mental Health Awareness month, Modern Health is thrilled to highlight
                the monumental work of several innovative companies who are proactively addressing
                mental health in the workplace. From creating cultures where mental health feels
                less taboo, to implementing preventative solutions to bolster a resilient and
                productive workforce, these companies are paving the way for what it means to
                support their employees' mental well-being.
            </RegularParagraph>
            <RegularParagraph>
                We have conversations every week with hundreds of different companies and thousands
                of employees from all over the world. Below are a list of companies that are
                championing a culture of support and are on a mission to address the epidemic of
                loneliness and depression. These companies inspire us to come to work every single
                day. Thank you for leading the way.
            </RegularParagraph>
            <BoldItalicParagraph>
                Alyson Friedensohn, Co-Founder/CEO and the entire Modern Health team
            </BoldItalicParagraph>
            <SectionTitle>Top Companies with Under 2k Employees</SectionTitle>
            {companiesUnder2K.map((company, i) => (
                <Company company={company} index={i} key={company.name} />
            ))}

            <SectionTitle>Top Companies with Over 2k Employees</SectionTitle>
            {companiesOver2K.map((company, i) => (
                <Company company={company} index={i} key={company.name} />
            ))}
        </ContentWrapper>
        <FooterWrapper homepage={1}>
            <WantToTakeCareSection />
        </FooterWrapper>
    </>
);

export default TopCompaniesPage;
