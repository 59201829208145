import React from 'react';
import styled from '@emotion/styled';
import { Col, Row, Button } from 'antd';

import { mediaQueries, color } from '../variables';
import RequestDemoFormModal from '../shared/components/RequestDemoFormModal';
import { RequestDemoButton } from '../shared/components/styles';

const ContentRow = styled(Row)`
  width: 100%;
  padding: 2rem 5rem;
  display: block;
  text-align: center;
  ${mediaQueries.xl} {
    padding: 0;
  }
`;

const SectionHeading = styled.h2`
  color: ${color.white};
  margin: '0 0 1rem';
  font-size: 40px;
  font-weight: 500;
  width: 50%;
  padding-bottom: 1rem;
  ${mediaQueries.sm} {
    font-size: 28px;
  }
`;

const SectionWrapper = styled(Col)`
  padding: 11rem 1rem 0rem;
  width: 100%;
  ${mediaQueries.sm} {
    padding: 2rem 1rem 0;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

`;

class WantToTakeCareSection extends React.Component {
  state = {
    isModalOpen: false,
  };

  toggleRequestDemoModal = () => this.setState(state => ({ isModalOpen: !state.isModalOpen }));

  render() {
    return (
      <>
        <SectionWrapper>
          <ContentRow>
            <HeaderWrapper>
              <SectionHeading>Find out how to make mental health more accessible for your people</SectionHeading>
              <RequestDemoButton onClick={this.toggleRequestDemoModal}>
                Request demo
              </RequestDemoButton>
            </HeaderWrapper>
          </ContentRow>
        </SectionWrapper>
        <RequestDemoFormModal
          isVisible={this.state.isModalOpen}
          onCancel={this.toggleRequestDemoModal}
        />
      </>
    );
  }
}

export default WantToTakeCareSection;
